//
// menu.scss
//

.navbar {
  transition: all 0.5s ease;
  padding: 20px 0px;
  .navbar-nav {
    .nav-item {
      .nav-link {
        color: #ec5210;
        font-size: 15px;
        font-weight: 600;
        background-color: transparent;
        margin: 0 10px;
        padding: 6px 0;
        transition: all 0.3s ease;
        &:hover,
        &.active {
          color: #ec5210 !important;
        }
      }
    }
  }
  .nav-toggle {
    color: #ffffff;
    border-radius: 5px;
    background-color: transparent;
    border-color: transparent;
    margin: 0 10px;
    padding: 0 0;
    transition: all 0.3s ease;
    &:hover,
    &:focus,
    &.active {
      color: #ec5210 !important;
      box-shadow: none;
    }
  }
  .navbar-toggler {
    padding: 0px;
    border: none;
    font-size: 24px;
    &:focus {
      box-shadow: none;
    }
  }
  .navbar-brand {
    .logo-light {
      display: inline-block;
    }
    .logo-dark {
      display: none;
    }
  }
}

//nav-sticy
.nav-sticky {
  &.navbar {
    background: $white;
    padding: 10px 0px;
    margin-top: 0px;
    box-shadow: 0px 3px 10px 0px rgba($dark, 0.08);
    .nav-item {
      .nav-link {
        color: #ec5210;
        &:hover,
        &:active {
          color: #ec5210;
        }
      }
    }
  }
  .navbar-brand {
    .logo-dark {
      display: inline-block;
    }
    .logo-light {
      display: none;
    }
  }
  .nav-toggle {
    color: $dark;
  }
}

.navbar {
  &.navbar-light {
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $dark;
        }
      }
    }
  }
}

.nav-sub {
  color: #3b3b42;
  font-size: 15px;
  &:hover {
    color: #ec5210;
  }
}

@media (min-width: 992px) {
  .navbar {
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $white;
        }
      }
    }
  }
  //nav-sticy
  .nav-sticky {
    &.navbar {
      background: $white;
      .nav-item {
        .nav-link {
          color: $dark;
        }
        .nav-toggle {
          color: $dark;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .navbar {
    padding: 12px 0px;
    background-color: transparent;

    .navbar-collapse {
      background-color: $white;
      border-radius: 5px;
      padding: 10px;
      margin-top: 10px;
    }
  }
}
