//
// general.scss
//

body {
  font-family: $font-family-base;
  color: $dark;
  font-size: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-base;
  font-weight: 700;
  line-height: 1.3;
}

a {
  text-decoration: none;
  outline: none;
  color: #ec5210;
  :hover {
    color: #ec5210;
  }
}

p {
  line-height: 1.6;
  white-space: pre-line;
  vertical-align: bottom;
}

html {
  scroll-padding-top: 50px;
  scroll-behavior: smooth;
}

.row > * {
  position: relative;
}

.p-content {
  text-align: justify;
  text-justify: inter-word;
}

.navbarDash {
  background-color: #ffffff;
}

.text-hubl {
  color: rgb(86, 86, 86);
}
