@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import "bootstrap/dist/css/bootstrap.min.css";
* {
  box-sizing: border-box;
}
.password {
  background-image: url("../../../assets/greenBG-01.png");
  background-color: rgba(#3b3b42, 0.4);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Lato", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
label,
span {
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

.loginContainer {
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  /* padding: 40px 55px 45px 55px; */
  border-radius: 15px;
  transition: all 0.3s;
}

.auth-wrapper .form-control:focus {
  border-color: #ec5210;
  box-shadow: none;
}
.auth-wrapper h3 {
  text-align: left;
  margin: 0;
  line-height: 1;
  padding-bottom: 10px;
}
.custom-control-label {
  font-weight: 400;
}
.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}
.forgot-password a {
  color: #ec5210;
}

.create-account {
  text-align: left;
  font-size: 13px;
  padding-bottom: 10px;
  color: #7f7d7d;
  margin: 0;
}

.logo-login {
  width: 90%;
  position: relative;
  margin-left: -20px;
  padding-bottom: 15px;
}

.banner-login {
  align-items: center;
}

// .bg-overlay {
//   background-color: rgba(#3b3b42, 0.6);
// }

// .nav-bar {
//   padding-left: 20px !important;
//   padding-right: 20px !important;
//   padding-top: 10px;
// }

/* .nav-link:active {
  border-bottom: 2px solid #ec5210;
} */
