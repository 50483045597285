

.switch-label {
   font-size: 13px;
   font-style: normal;
   margin-bottom: 7px;
   padding-left: 5px;
}


.switch-object {

min-width: 138px;
height: 31px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 5px;
margin-right: 16px;
margin-bottom: 16px;
font-size: 14px;
color: #333333;
opacity: 1;
}