.powerbi-box{
   height: 1500px;
   overflow: hidden
}

.powerbi-box-mobile{
  height: 3000px;
  overflow: hidden
}

.dashboard-styling-class{
  height: 1500px; 
  position: relative; 
}

.dashboard-styling-class-mobile{
  height: 3000px; 
  position: relative; 
}
.custom-height{
  min-height:80vh
}
.home {
  display: flex;
  background-color: #f0f0f0;

  .homeContainer {
    flex: 6;

    .widgets,
    .charts {
      display: flex;
      padding: 20px;
      padding-top: 0px;
      gap: 20px;
    }

    .charts {
      padding: 5px 20px;
    }

    .listContainer {
      // -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      // box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 8px;

      .listTitle {
        font-weight: 500;
        color: lightgray;
        margin-bottom: 15px;
      }
    }
  }
}
.report-heading{
  margin-left: 40px;
  margin-top: 40px;
}

.report-subheading{
  margin-left: 45px;
  color: gray;
}
