//
// variables.scss
//

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

$primary: #ec5210;
$success: #00b295;
$info: #57b8ff;
$warning: #fbb13c;
$danger: #fe6847;
$purple: #9261c6;
$pink: #ff7aa3;
$white: #ffffff;
$dark: #161c2d;
$light: #f9f9f9;
$muted: #8492a6;

// stylelint-disable
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #efefef;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000000;
$white: #ffffff;

$colors: (
  "primary": $primary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "dark": $dark,
  "muted": $muted,
  "purple": $purple,
  "pink": $pink,
  "white": $white,
  "light": $light,
);

$theme-colors: (
  "primary": $primary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "muted": $muted,
  "white": $white,
);

$theme: (
  light: (
    colorHeaderFooter: #fff,
    colorText: #000,
    colorBackground: #f8f8fa,
  ),
);

// Body Background
$body-bg: #f5f5f5;
$box-shadow: 0px 10px 22px rgba($black, 0.05);

// font weight
$font-weight-medium: 400;
$font-weight-semibold: 700;

$font-family-base: "Lato", sans-serif;
