.autocomplete-label {
  font-size: 13px;
  font-style: normal;
  margin-bottom: 7px;
  padding-left: 5px;
}

.autocomplete-object {
  min-width: 300px;
  background-color: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 5px;
  margin-right: 5px;
  // margin-bottom: 15px;s
  font-size: 14px;
  color: #333333;
  opacity: 1;
}
