.select-label {
  font-size: 13px;
  font-style: normal;
  margin-bottom: 7px;
  padding-left: 5px;
}

.select-object {
  min-width: 138px;
  height: 37px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 8px;
  margin-right: 5px;
  margin-bottom: 15px;
  font-size: 14px;
  color: #333333;
  opacity: 1;
}
