.navbarDash {
  background-color: #ffffff;
  height: auto;
  display: flex;
  font-size: 14px;
  color: #555;

  .logo-login {
    height: 50px;
    width: auto;
    padding-left: 60px;
  }

  .MuiList-root {
    color: "white";
    background-color: "black";
  }

  .icon {
    color: gray;
  }

  .nav-link {
    font-weight: 700;
  }

  .nav-link.active {
    border-bottom: #ec5210 solid 2px;
  }

  .wrapper {
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .search {
      display: flex;
      align-items: center;
      border: 0.5px solid lightgray;
      padding: 3px;

      input {
        border: none;
        outline: none;
        background: transparent;

        &::placeholder {
          font-size: 12px;
        }
      }
    }

    @media (max-width: 599px) {
      .hidden-mob {
        display: none !important;
      }
    }

    .items {
      display: flex;
      align-items: center;

      .item {
        display: flex;
        align-items: center;
        margin-right: 20px;
        position: relative;

        .icon {
          font-size: 20px;
        }

        .avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }

        .counter {
          width: 15px;
          height: 15px;
          background-color: red;
          border-radius: 50%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          position: absolute;
          top: -5px;
          right: -5px;
        }
      }
    }
  }
}
