.logoContainer {
  position: relative;
  width: 290px;
  display: flex; /* Add flex display */
  justify-content: start; /* Center the logos horizontally */
  align-items: start; /* Center the logos vertically */
  height: 130px;
  overflow: hidden;
}

.logo {
  margin-left: -10px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: step-end 1s;
  width: 100%;
}

.logo.visible {
  opacity: 1;
}
