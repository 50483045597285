.logoContainer {
  position: relative;
  width: 400px;
  display: flex; /* Add flex display */
  justify-content: center; /* Center the logos horizontally */
  align-items: center; /* Center the logos vertically */
  height: 180px;
  overflow: hidden;
}

.logo {
  margin-left: -10px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: step-end 1s;
  width: 90%;
}

.logo.visible {
  opacity: 1;
}

@media screen and (max-width: 600px) {
  /* your CSS rules go here */
  .logoContainer {
    width: 300px;
    height: 120px;
  }
}
