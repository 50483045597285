.sidebar {
  // position: fixed;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  flex: 0 0 50px;
  min-width: 240px;
  max-width: 240px;
  min-height: 100vh;
  background-color: #ffffff;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
  box-shadow: 1px 1px 7px 0px #9a9aa3;

  li {
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-bottom: 7px;
    background-color: #e3e3e3;

    cursor: pointer;

    &:hover {
      background-color: #ec5210c2;
      color: #fff;
    }
    .icon {
      font-size: 18px;
    }

    .details {
      background-color: #ec5210;
    }

    span {
      font-size: 13px;
      font-weight: 600;
      color: #888;
      margin-left: 10px;
    }
  }

  .item-active {
    background-color: #f47f65;
    color: #fff;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 1px 1px 5px 0px #9f9fa3;
  }
  .list-item {
    color: #ffffff;
  }

  .topbar {
    margin-top: 2px;
    display: flex;
    justify-content: center;
    font-family: "Lato", sans-serif;
  }

  .topbar-list {
    // border-radius: 0px;
    // padding: 0px;
    // margin-top: 7px;
    // margin-left: 15px;
    margin: 0px;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    cursor: pointer;
  }
  .section {
    margin: 0;
    padding: 0;
    background-color: transparent;
  }
  .logo-brand {
    letter-spacing: 1px;
    font-style: italic;
  }

  .top {
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      font-size: 20px;
      font-weight: bold;
      color: #3b3b42;
    }

    hr {
      width: 60%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .center {
    padding-top: 0px;
  }

  .imageLogo {
    // transform: translateY(30px);
  }

  .image-logo {
    background-color: #fff;
    padding: 0px;
    margin-left: 0px;
    margin-top: 0px;
  }

  .image-dark {
    content: url("../../../assets/logo-dark.png");
  }
  .avatar {
    margin-right: 5px;
  }
  .company {
    padding-left: 0px;
    font-weight: Bold;
    font-family: "Lato", sans-serif;
    color: #3b3b42;
    // display: flex;
    // align-items: flex-start;
    // justify-content: start;
    // font-size: 18px;

    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // max-width: 180px;
  }

  .client {
    padding-top: 5px;
    font-size: 14px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e3e3e3;
  }

  hr {
    height: 2;
    border: 1px solid grey;
    margin-top: 5px;
    opacity: 30%;
    width: 75%;
  }

  .center {
    padding-left: 15px;
    padding-right: 15px;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 10px;
        font-weight: bold;

        padding: 0;
        // margin-top: 15px;
        // margin-bottom: 5px;
      }
      .details {
        padding: 0;
        margin: 0;
      }
    }
  }

  .bottom {
    display: flex;
    align-items: center;
    margin-left: 10px;

    .colorOption {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      border: 1px solid #e3e3e3;
      cursor: pointer;
      margin: 5px;

      &:nth-child(1) {
        background-color: whitesmoke;
      }
      &:nth-child(2) {
        background-color: #333;
      }
      &:nth-child(3) {
        background-color: darkblue;
      }
    }
  }

  .bottomHide {
    margin-top: auto;

    padding: 10px;

    align-items: center;
    justify-content: center;
  }

  .containerSB {
    background-color: #fff;
    height: 80px;
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 24;
  height: 24;
  left: 20px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #3b3b42;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ec5210;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: rgb(141, 141, 141);
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: transparent;
  padding: 0;
}

/* Wrapper for item list */
// .bm-item-list {
//   color: #b8b7ad;
//   padding: 0.8em;
// }

/* Styling of overlay */
.bm-overlay {
  background: transparent;
}
