.ticket-container{
    overflow: hidden;
    padding: 20px;
  }

  
  // .ticket-box {
  //   padding-left:25px;
  //   padding-right:25px;
  // }
  
  // .selector-box{
  //   padding-left:25px;
  //   padding-top:25px;
  // }
  
  .ticket-logo {
  width: 39px;
  height: 39px;
  background: 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-right:5px;
  }
  
  .project-heading {
  text-align: left;
  font: normal normal bold 14px/17px;
  letter-spacing: 0px;
  color: #3B3B42;
  opacity: 1;
  }
  
  .project-ticket {
  text-align: left;
  font: italic normal medium 10px/12px;
  letter-spacing: 0px;
  color: #3B3B42;
  opacity: 1;
  margin-right: 7px;
  font-size: 10px;
  
  }
  .project-type{
  text-align: center;
  background: #F2F2F2 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  font-size: 8px;
  margin-right: 7px;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2px;
  padding-bottom: 2px;
  height: 17px;
  }

  .project-priority{
    font-size: 8px;
    border-radius: 6px;
    opacity: 1;
    margin-right: 7px;
    text-align: center;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 2px;
    padding-bottom: 2px;
    height: 17px;
 
  }
  
  .high{
    min-width: 30px;
    background: #D56363 0% 0% no-repeat padding-box;
    color: #FFFFFF;
  }
  .medium{
    min-width: 40px;
    background: #eab238 0% 0% no-repeat padding-box;
    color: #FFFFFF;
  }
  .low{
    min-width: 25px;
    background: #59c22f 0% 0% no-repeat padding-box;
    color: #FFFFFF;
  }
  
  .detail-label {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
  }
  
  
  
  .detail-block {
  /* UI Properties */
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #D6D6D6;
  opacity: 1;
  padding-left:12px;
  padding-top:9px;
  padding-right:31px;
  padding-bottom:10px;
  margin-bottom:13px;
  margin-right:13px;
  font-size: 12px;
  max-width: 45vw;
  }
  
  .block-subject {
    min-height: 118px;
    word-wrap: break-word  !important;
  }
  
  