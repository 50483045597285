@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import "bootstrap/dist/css/bootstrap.min.css";
* {
  box-sizing: border-box;
}
.register {
  background-image: url("../../../assets/greenBG-01.png");
  background-color: rgba(#3b3b42, 0.4);
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: multiply;
  min-height: 130vh;
  display: flex;
  font-weight: 400;
  font-family: "Lato", sans-serif;
}

.registerContainer {
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all 0.3s;
}
.logo-register {
  height: 110px;
  position: relative;
  padding-bottom: 20px;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}

.heading-register {
}

.heading-title {
  padding-bottom: 20px;
}

// .bg-overlay {
//   background-color: rgba(#3b3b42, 0.6);
// }

// .nav-bar {
//   padding-left: 20px !important;
//   padding-right: 20px !important;
//   padding-top: 10px;
// }

/* .nav-link:active {
  border-bottom: 2px solid #ec5210;
} */
.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #ea5120;
  opacity: 70%;
  color: #fff;
  padding: 0.5rem;
  position: relative;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  display: none;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}
