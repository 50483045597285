.notification-container{
    overflow: hidden;
    margin: 20px;
  }

.note-type{
    text-align: center;
    display: inline-block;
    background: #1A76D2 0% 0% no-repeat padding-box;
    color: white;
    border-radius: 6px;
    opacity: 1;
    font-size: 8px;
    margin-right: 7px;
    padding-bottom: 2px;
    padding-left: 6px;
    padding-right: 6px;
    height: 13px;
    width:80px;
    }
  
.note-date{
      text-align: left;
      display: inline-block;
      opacity: 1;
      font-size: 8px;
      margin-right: 7px;
      padding-left: 6px;
      padding-right: 6px;
      padding-bottom: 2px;
      height: 13px;
      }
  
      .note-type, .note-date {
        vertical-align: middle;
    }